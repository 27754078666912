import './css/dashbord.css';
import Capsules from './dashbord/capsules';
import Chart from 'react-apexcharts'


const DashbordQTD = (props) => {
    const dashbordDetailsByQuarter = (props.dashbordDetailsByQuarter) ? props.dashbordDetailsByQuarter : "";
    
    return (
        <>
        <div className='section_2a' style={{paddingBottom:'50px'}}>
          <Capsules name={"Program Expenses (QTD)\n(in Lakhs)"} value={dashbordDetailsByQuarter?.staticData?.programCount?.toLocaleString('hi-IN')} />
          <Capsules name={"Program Expenses \n% of Annual Budget (QTD)"} value={dashbordDetailsByQuarter?.staticData?.programPercent?.toLocaleString('hi-IN')+' %'} />
          <Capsules name={'Kits Distributed'} value={dashbordDetailsByQuarter?.staticData?.kitsDistributed?.toLocaleString('hi-IN')} />
          <Capsules name={'No. Of Sparks'} value={dashbordDetailsByQuarter?.staticData?.spark?.toLocaleString('hi-IN')} />
          <Capsules name={'Sampark TV/LED distributed'} value={dashbordDetailsByQuarter?.staticData?.stvLed?.toLocaleString('hi-IN')} />
        </div>
        {/* Teacher Training */}
        <div className='section_3' style={{paddingBottom:'60px'}}>
          <div className='section_3a'>
            <div className='section_3_head_a_container' >
              <div className='section_3_head1'>Teacher Training</div>
            </div>
            <div className='section_3_head_b_container'>
              <div className='section_3_head1'>
                Teaching Acceptance Measurement
              </div>
            </div>
          </div>
          <div className='section_3b'>
            <div className='section_3_b1' >
              <Capsules name={"Teachers trained"} value={dashbordDetailsByQuarter?.dashboardData?.teachers_trained_qtd?.toLocaleString('hi-IN') || dashbordDetailsByQuarter?.staticData?.teachers_trained?.toLocaleString('hi-IN')} /*{dashbordDetailsByQuarter?.teacherData?.trained_teachers?.toLocaleString('hi-IN')}*/ />
              <Capsules name={"MT Trained"} value={dashbordDetailsByQuarter?.dashboardData?.mt_trained_qtd?.toLocaleString('hi-IN') || dashbordDetailsByQuarter?.staticData?.mt_trained?.toLocaleString('hi-IN')} /*{dashbordDetailsByQuarter?.mtteacherData?.trained_teachers?.toLocaleString('hi-IN')} */ />
            </div>
            <div className='section_3b_container' >
              <Capsules name={"No of Feedbacks"} value={dashbordDetailsByQuarter?.dashboardData?.no_of_feedbacks_qtd?.toLocaleString('hi-IN') || (dashbordDetailsByQuarter?.schoolData?.total_feedback + dashbordDetailsByQuarter?.meetingFeedback?.total_feedback + dashbordDetailsByQuarter?.trainingData?.total_feedback).toLocaleString('hi-IN')} />
              <Capsules name={"Training Acceptance"} value={`${dashbordDetailsByQuarter?.dashboardData?.training_acceptance_qtd?.toLocaleString('hi-IN') || dashbordDetailsByQuarter?.trainingData?.rating}`.slice(0, 3) + "/5"} />
              <Capsules name={"Program acceptance"} value={`${dashbordDetailsByQuarter?.dashboardData?.program_acceptance_qtd?.toLocaleString('hi-IN') || dashbordDetailsByQuarter?.schoolData?.rating}`.slice(0, 3) + "/5"} />
            </div>
          </div>
        </div>
        {/* classroom impact measured */}
        <div className='section_4' style={{paddingBottom:'60px'}}>
          <div className='section_4a'>
            <div className='section_3_head1'>
              Classroom Impact Measured
            </div>
          </div>
          <div className='section_4b'>
            <div className='section_4_card_1' >
              <div className='card_head'>Registered Users</div>
              <div className='card_head_2' style={{ padding: "33.5px 35px " }} >{dashbordDetailsByQuarter?.dashboardData?.registered_users_qtd?.toLocaleString('hi-IN') || dashbordDetailsByQuarter?.registeredUsers?.users_registered?.toLocaleString('hi-IN')} (QTD)</div>
              <div className='card_head_3' style={{ padding: "20px 35px " }} >{dashbordDetailsByQuarter?.dashboardData?.registered_users_ytd?.toLocaleString('hi-IN') || (dashbordDetailsByQuarter?.previousData?.registered_users_qtd + dashbordDetailsByQuarter?.registeredUsers?.users_registered).toLocaleString('hi-IN')} (YTD)</div>
            </div>
            <div className='section_4_card_2' >
              <div className='card_head'>Total Resources Used</div>
              <div className='card_head_2' style={{ padding: "33.5px 35px " }} >{dashbordDetailsByQuarter?.dashboardData?.total_resources_used_qtd?.toLocaleString('hi-IN') || dashbordDetailsByQuarter?.resourcesUsedTotal?.toLocaleString('hi-IN')} (QTD)</div>
              <div className='card_head_3' style={{ padding: "20px 35px " }} >{dashbordDetailsByQuarter?.dashboardData?.total_resources_used_ytd?.toLocaleString('hi-IN') || (dashbordDetailsByQuarter?.previousData?.total_resources_used_qtd + dashbordDetailsByQuarter?.resourcesUsedTotal).toLocaleString('hi-IN')} (YTD)</div>
            </div>
            <div className='section_4_card_3' >
              <div className='card_head'>Subject Resources Used (QTD)</div>
              <div className='card_3_line_1'>
                <div className='card_2_lable' >English</div>
                <div className='card_2_value'>{dashbordDetailsByQuarter?.dashboardData?.english_resources_used_qtd?.toLocaleString('hi-IN') || dashbordDetailsByQuarter?.resourcesUsed?.english?.toLocaleString('hi-IN')}</div>
              </div>
              <div className='card_3_line_2' >
                <div className='card_2_lable'>Math</div>
                <div className='card_2_value'>{dashbordDetailsByQuarter?.dashboardData?.math_resources_used_qtd?.toLocaleString('hi-IN') || dashbordDetailsByQuarter?.resourcesUsed?.math?.toLocaleString('hi-IN')}</div>
              </div>
              <div className='card_3_line_3' >
                <div className='card_2_lable'>Science</div>
                <div className='card_2_value'>{(dashbordDetailsByQuarter?.dashboardData?.science_resources_used_qtd?.toLocaleString('hi-IN') || dashbordDetailsByQuarter?.resourcesUsed?.science?.toLocaleString('hi-IN'))}</div>
              </div>
              <div className='card_3_line_4' >
                <div className='card_2_lable'>Other Subjects</div>
                <div className='card_2_value'>{(dashbordDetailsByQuarter?.dashboardData?.other_subject_resources_used_qtd?.toLocaleString('hi-IN') || dashbordDetailsByQuarter?.resourcesUsed?.otherSubjects?.toLocaleString('hi-IN'))}</div>
              </div>
            </div>
            <div className='section_4_card_3' >
              <div className='card_head'>Class Resources Used (QTD)</div>
              <div className='card_2_line_1' >
                <div className='card_2_lable'>FLN</div>
                <div className='card_2_value'>{dashbordDetailsByQuarter?.dashboardData?.fln_resources_used_qtd?.toLocaleString('hi-IN') || dashbordDetailsByQuarter?.resourcesUsed?.FLN?.toLocaleString('hi-IN')}</div>
              </div>
              <div className='card_2_line_2'>
                <div className='card_2_lable'>Class 6-8</div>
                <div className='card_2_value'>{dashbordDetailsByQuarter?.dashboardData?.class68_resources_used_qtd?.toLocaleString('hi-IN') || dashbordDetailsByQuarter?.resourcesUsed?.class6_8?.toLocaleString('hi-IN')}</div>
              </div>
              <div className='card_2_line_3'>
                <div className='card_2_lable'>Other</div>
                <div className='card_2_value'>{dashbordDetailsByQuarter?.dashboardData?.otherclass_resources_used_qtd?.toLocaleString('hi-IN') || dashbordDetailsByQuarter?.resourcesUsed?.otherClass?.toLocaleString('hi-IN')}</div>
              </div>
            </div>
          </div>
        </div>

        {/* Program monitoring evaluation */}
        <div className='section_5' >
          <div className='section_4a'>
            <div className='section_3_head1'>Program Monitoring Evaluation</div>
          </div>
          <div className='section_5b' >
            <div className='section_5_capsules'>
              <Capsules name={"School audit and review"} value={dashbordDetailsByQuarter?.dashboardData?.school_audit_and_review_qtd?.toLocaleString('hi-IN') || (dashbordDetailsByQuarter?.schoolVisits?.visitcount - dashbordDetailsByQuarter?.managerSchoolVIsits?.visitcount + dashbordDetailsByQuarter?.meetingData?.meeting_count - dashbordDetailsByQuarter?.managerMeetingData?.meeting_count).toLocaleString('hi-IN')} />
              <Capsules name={"State advocacy Meeting"} value={dashbordDetailsByQuarter?.dashboardData?.state_advocacy_meeting_qtd?.toLocaleString('hi-IN') || (dashbordDetailsByQuarter?.managerSchoolVIsits?.visitcount + dashbordDetailsByQuarter?.managerMeetingData?.meeting_count).toLocaleString('hi-IN')} />
            </div>
          </div>
        </div>
      
        </>
        
    );
}

export default DashbordQTD
