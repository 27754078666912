import "./App.css";
import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Navbar from "./components/Navbar";
import StaticDataByFunder from "./pages/StaticDataByFunder";
import StaticDataList from "./pages/StaticDataList";
import PreviewPdf from "./pages/PreviewPdf"

import Awards from "./pages/Awards";
import UserList from "./pages/UserList";
import StaticDataEdit from "./pages/StaticDataEdit";
import { connect } from "react-redux";
import { log_out } from "./redux/actions/userActions";
import FunderGroup from "./pages/FunderGroup";
import FunderList from "./pages/FunderList";
import FunderEdit from "./pages/FunderEdit";
import UserEdit from "./pages/UserEdit";
import FreezeFunderData from "./pages/FreezeFunderData";

function App(props) {
  // const user = props.user;
  // const [UserDetails, setUserDetails] = useState("");
  // const [Loading, setLoading] = useState({
  //   loading: false,
  //   success: "",
  //   error: "",
  // });

  // useEffect(() => {
  //   if (user?.token) {
  //     userDetails();
  //   } else if (user?.token === null) {
  //     setUserDetails("");
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [user]);

  // const logout = (e) => {
  //   e.preventDefault();
  //   log_out();
  //   History('/login');
  // };

  // const userDetails = async () => {
  //   try {
  //     setLoading({
  //       loading: true,
  //       success: "",
  //       error: "",
  //     });
  //     const {
  //       data: { data },
  //     } = await axios(
  //       `${configKeys.SERVER_URL}/userDetails?token=${user.token}`
  //     );
  //     console.log(data);
  //     setUserDetails(data);
  //     setLoading({
  //       loading: false,
  //       success: "",
  //       error: "",
  //     });
  //   } catch (error) {
  //     console.log(error.message);
  //     logout();
  //     setLoading({
  //       loading: false,
  //       success: "",
  //       error: error?.response?.data?.message,
  //     });
  //   }
  // };

  return (
    <div className="App">
      {/* {Loading.loading  ? (
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      ) : (
        <> */}
          <Navbar />
          <div style={{ paddingTop: "73px" }}>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/FreezeFunderData/:id" element={<FreezeFunderData name={"School"} showHeader={true} />} />
              <Route path="/TopSchools/:id" element={<Awards name={"School"} showHeader={true} />} />
              <Route path="/TopTeachers/:id" element={<Awards name={"Teacher"} showHeader={true} />} />
              <Route path="/register" element={<Register />} />
              <Route path="/login" element={<Login />} />
              <Route path="/userList" element={<UserList  />} />
              <Route path="/userEdit/:id" element={<UserEdit  />} />
              <Route path="/staticDataList/:id" element={<StaticDataList />} />
              <Route path='/staticDataByFunder' element={<StaticDataByFunder/>}  />
              <Route path="/funderGroup" element={<FunderGroup />} />
              <Route path="/funderGroupList" element={<FunderList />} />
              <Route path="/funderGroupEdit/:id" element={<FunderEdit />} />
              {/* <Route path='/sssKitDistributed' element={<SSSkitDistributed/>}  /> */}
              {/* <Route path="/userDetails/:id" element={<StaticDataList  />} /> */}
              <Route path="/editStaticDataByFunder/:id" element={<StaticDataEdit  />} />
              {/* <Route path='/childrenImpacted/list' element={<ChildrenImpactedList/>}  /> */}
              {/* <Route path='/sssKitDistributed/list' element={<SSSkitDistributedList/>}  /> */}
              {/* <Route path='/schoolImpacted/:id' element={<SchoolImpactedDetails/>}  /> */}
              {/* <Route path='/childrenImpacted/:id' element={<ChildrenImpactedDetails/>}  /> */}
              {/* <Route path='/sssKitDistributed/:id' element={<SSSkitDistributedDetails/>}  /> */}
              <Route path='/previewPdf/:id' element={<PreviewPdf/>}  />
              <Route path="*" element={<HomePage />} />
            </Routes>
          </div>
        {/* </>
      )} */}
    </div>
  );
}

const mapStateStore = (stateStore) => {
  return {
    user: stateStore.userState.user,
  };
};

export default connect(mapStateStore, {log_out})(App);
