import axios from 'axios';
import React, { useEffect, useState } from 'react';
import configKeys from '../../config/keys';
import inputValidation from '../../constants/inputValidaation';
import './css/dashbord.css';
import Select from 'react-select';
import { Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import DashbordYTD from './DashbordYTD';
import DashbordQTD from './DashbordQTD';


const Dashbord = (props) => {
  const userDetails = props.userData
  const user = props.user
  const mHistory = useNavigate();

  const month = { '1': 'January, February, March' ,
    '4': 'April, May, June' ,
    '7': 'July, August, September' ,
    '10': 'October, November, December' };
    
  const [selectedOption, setSelectedOption] = useState('');
  const [months, setMonths] = useState([]);
  const [funderType, setFunderType] = useState('');
  const [dashbordDetails, setdashbordDetails] = useState('');
  const [dashbordDetailsByQuarter, setdashbordDetailsByQuarter] = useState('');
  const [funderList, setFunderList] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [topDistrict, setTopDistrict] = useState([]);
  // const [topBlocks, setTopBlocks] = useState([]);
  // const [districtList, setDistrictList] = useState([]);
  // const [blockList, setBlockList] = useState([]);
  // const [stateList, setstateList] = useState([]);
  const [formData, setformData] = useState({
    year: '',
    quarter: '',
    state_id: [],
    district_id: '',
    districtName: '',
    block_id: '',
    blockName: '',
    curMonthYear: '',
    startMonthYear: '',
    allState: true,
  });
  const [fromDataError, setFromDataError] = useState({
    year: '',
    quarter: '',
    state_id: '',
    district_id: '',
    curMonthYear: '',
  });

  // useEffect(() => {
  //   getStateList();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const token = JSON.parse(localStorage.getItem('user'));
  useEffect(() => {
    if (!user || user === null) {
      return mHistory('/login');
    }
    if (!token) return mHistory('/login');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    getFunderList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedOption) {
      if (months.length === 0) {
        let startMonth = parseInt(selectedOption['programStartMonth'])
        let startYear = selectedOption['programStartYear']
        let endMonth = parseInt(selectedOption['programEndMonth'])
        let endYear = selectedOption['programEndYear']
        const date = new Date();
        const curYear = date.getFullYear()
        const curMonth = date.getMonth()+1
        let tempmonths = []
        let quarter = 1
        if (startYear === endYear) {
          for (let i=startMonth;i<=endMonth;i=i+3) {
            if (parseInt(startYear) < parseInt(curYear)) {
              tempmonths.push({name:month[i.toString()]+' - '+startYear,value:quarter+"-"+i+"-"+startYear})
            } else if (parseInt(startYear) === parseInt(curYear) && i <= curMonth) {
              tempmonths.push({name:month[i.toString()]+' - '+startYear,value:quarter+"-"+i+"-"+startYear})
            }
            quarter = quarter + 1
          }
        } else {
          let tempYear = parseInt(startYear)
          while (tempYear <= parseInt(endYear)) 
          { 
            let tempStartMonth = 1
            let tempEndMonth = 12
            if (tempYear === parseInt(startYear)) {
              tempStartMonth = startMonth
            }
            if (tempYear === parseInt(endYear)) {
              tempEndMonth = endMonth
            }
            for (let i=tempStartMonth;i<=tempEndMonth;i=i+3) {
              if ((parseInt(tempYear) === parseInt(curYear) && i <= curMonth) || (parseInt(tempYear) < parseInt(curYear))) {
                tempmonths.push({name:month[i.toString()]+' - '+tempYear,value:quarter+"-"+i+"-"+tempYear})
                quarter = quarter + 1
              }
            }
            tempYear = tempYear + 1
          }
        }
        setMonths(tempmonths)
        setformData({
          ...formData,
          curMonthYear: tempmonths[0]['value'],
          startMonthYear: startMonth+"-"+startYear,
        });
      }
      getDasbordDetails();
      getDasbordDetailsByQuarter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption, formData.curMonthYear]);

  const getDasbordDetails = async () => {
    try {
      if (checkIfError()) {
        return;
      }
      let dashbordData = {
        funderId: (userDetails.admintype === "superadmin" || userDetails.admintype === "program_manager" || userDetails.admintype === "admin") ? selectedOption['value'] : userDetails.funderId,
        startMonthYear: formData.startMonthYear,
        curMonthYear: formData.curMonthYear,
      };
      const { data } = await axios.post(`${configKeys.SERVER_URL}/v3/dashboardFunder`, { ...dashbordData }, { headers: { "Authorization": user?.token } });
      setdashbordDetails(data.data);
    } catch (error) {
      console.log(error)
    }
  };

  const getDasbordDetailsByQuarter = async () => {
    try {
      if (checkIfError()) {
        return;
      }
      let dashbordData = {
        funderId: (userDetails.admintype === "superadmin" || userDetails.admintype === "program_manager" || userDetails.admintype === "admin") ? selectedOption['value'] : userDetails.funderId,
        startMonthYear: formData.startMonthYear,
        curMonthYear: formData.curMonthYear,
      };
      const { data } = await axios.post(`${configKeys.SERVER_URL}/v3/dashboardFunderByQuarter`, { ...dashbordData }, { headers: { "Authorization": user?.token } });
      setdashbordDetailsByQuarter(data.data);
    } catch (error) {
      console.log(error)
    }
  };

  const getFunderList = async () => {
    try {
      setIsLoading(true)
      const { data } = await axios.get(`${configKeys.SERVER_URL}/v3/funderGroupList?label=true`)
      setFunderList(data.data)
      if (data.data.length === 0) return mHistory('/funderGroup');
      setSelectedOption(data?.data[0])
      if (data?.data[0]['science'] === true && data?.data[0]['fln'] === false)
        setFunderType('SCIENCE')
      else
        setFunderType('FLN')
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const checkIfError = () => {
    let hasError = false; // False means the form has no error and it can be submitted
    let tempError = {
      state_id: '',
    };
    if (inputValidation.isInputHasLength(selectedOption) !== '') {
      tempError.state_id = inputValidation.isInputHasLength(formData.state_id);
      if (formData.allState) {
        hasError = false;
        tempError.state_id = ''
      } else hasError = true;
    }
    setFromDataError({
      ...fromDataError,
      ...tempError,
    });
    console.log({ hasError });
    return hasError;
  };

  const handleChange = (selected) => {
    setSelectedOption(selected)
    if (selected['science'] === true && selected['fln'] === false)
      setFunderType('SCIENCE')
    else
      setFunderType('FLN')

    if (selected) {     
      let startMonth = parseInt(selected['programStartMonth'])
      let startYear = selected['programStartYear']
      let endMonth = parseInt(selected['programEndMonth'])
      let endYear = selected['programEndYear']
      const date = new Date();
      const curYear = date.getFullYear()
      const curMonth = date.getMonth()+1
      let tempmonths = []
      let quarter = 1
      if (startYear === endYear) {
        for (let i=startMonth;i<=endMonth;i=i+3) {
          if (parseInt(startYear) < parseInt(curYear)) {
            tempmonths.push({name:month[i.toString()]+' - '+startYear,value:quarter+"-"+i+"-"+startYear})
          } else if (parseInt(startYear) === parseInt(curYear) && i <= curMonth) {
            tempmonths.push({name:month[i.toString()]+' - '+startYear,value:quarter+"-"+i+"-"+startYear})
          }
          quarter = quarter + 1
        }
      } else {
        let tempYear = parseInt(startYear)
        while (tempYear <= parseInt(endYear)) 
        { 
          let tempStartMonth = 1
          let tempEndMonth = 12
          if (tempYear === parseInt(startYear)) {
            tempStartMonth = startMonth
          }
          if (tempYear === parseInt(endYear)) {
            tempEndMonth = endMonth
          }
          for (let i=tempStartMonth;i<=tempEndMonth;i=i+3) {
            if ((parseInt(tempYear) === parseInt(curYear) && i <= curMonth) || (parseInt(tempYear) < parseInt(curYear))) {
              tempmonths.push({name:month[i.toString()]+' - '+tempYear,value:quarter+"-"+i+"-"+tempYear})
              quarter = quarter + 1
            }
          }
          tempYear = tempYear + 1
        } 
      }
      setMonths(tempmonths)
      setformData({
        ...formData,
        curMonthYear: tempmonths[0]['value'],
        startMonthYear: startMonth+"-"+startYear,
      });
    }
  };

  return (
    <div className="container">
      {dashbordDetails ? (

        <div className="col-12 row mb-5">
          {/* {part 1} */}
          {/* Program Investment */}
          <div className='section_1'>
            <div className='d-flex justify-content-between flex-column'>
              <div className='section_1_year'>
                {dashbordDetailsByQuarter ? dashbordDetailsByQuarter.ytdname : ''}
              </div>
              <div className='section_1_head'>
                Program Investment
              </div>
            </div>

            <div className='' >
              <div className='d-flex justify-content-end'>
                {(userDetails.admintype === "superadmin" || userDetails.admintype === "program_manager" || userDetails.admintype === "admin") ? (
                  <div className={'_input_container'}>
                    <label className={'_label'}>Select Program</label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      //  defaultValue={funderList && funderList[0] }
                      value={selectedOption}
                      isLoading={isLoading}
                      isClearable={false}
                      isSearchable={true}
                      name="color"
                      options={funderList}
                      onChange={handleChange}
                    />
                    {fromDataError.state_id && (
                      <span className="text-danger">{fromDataError.state_id}</span>
                    )}
                  </div>
                ) :
                  <div>
                    <div className={'_input_container'}>
                      <label className={'_label'}>&nbsp;</label>
                    </div>
                    <div className={'_input_container'}>
                      <label className={'_label'}>&nbsp;</label>
                    </div>
                  </div>
                }
              </div>
              <Link to={`/FreezeFunderData/${(userDetails.admintype === "superadmin1" || userDetails.admintype === "program_manager1" || userDetails.admintype === "admin1") ? selectedOption['value'] : userDetails.funderId}?startMonthYear=${formData.startMonthYear}&curMonthYear=${formData.curMonthYear}`} className='section_1_btn_text d-flex float-right' style={{ float: "right" }} >Freeze Current Dashboard</Link>
              <Link target="_blank" to={`/previewPdf/${(userDetails.admintype === "superadmin" || userDetails.admintype === "program_manager" || userDetails.admintype === "admin") ? selectedOption['value'] : userDetails.funderId}?startMonthYear=${formData.startMonthYear}&curMonthYear=${formData.curMonthYear}`} className='section_1_btn_text d-flex float-right' style={{ float: "right" }} >PDF Preview</Link>
              <Link to={`/TopSchools/${(userDetails.admintype === "superadmin" || userDetails.admintype === "program_manager" || userDetails.admintype === "admin") ? selectedOption['value'] : userDetails.funderId}?startMonthYear=${formData.startMonthYear}&curMonthYear=${formData.curMonthYear}`} className='section_1_btn_text d-flex float-right' style={{ float: "right" }} >Top Schools</Link>
              <Link to={`/TopTeachers/${(userDetails.admintype === "superadmin" || userDetails.admintype === "program_manager" || userDetails.admintype === "admin") ? selectedOption['value'] : userDetails.funderId}?startMonthYear=${formData.startMonthYear}&curMonthYear=${formData.curMonthYear}`} className='section_1_btn_text d-flex float-right' style={{ float: "right" }} >Top Teachers</Link>
            </div>
          </div>
          <DashbordYTD dashbordDetails={dashbordDetails} dashbordDetailsByQuarter={dashbordDetailsByQuarter} funderType={funderType} />
        </div>
      ) : (
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only"></span>
        </div>
      )}
      <div className='border_bottom_line' >

      </div>
      {/* part 2 */}
      <div className="col-12 row mb-5">
        {/* Program Investment */}
        <div className='section_1'>
          <div>
            <div className='section_1_year'>
              {dashbordDetailsByQuarter ? dashbordDetailsByQuarter.qtdname : ''}
            </div>
            <div className='section_1_head'>
              Program Investment
            </div>
          </div>
          <div className='section_1_btn'>
            <div className={'_input_container'}>
              {fromDataError.year && (
                <span className="text-danger">{fromDataError.year}</span>
              )}
            </div>
            <div className={'_input_container'}>
              <label className={'_label'}>Select Quarter </label>
              <select
                className={'_input'}
                style={{ cursor: 'pointer' }}
                id="month"
                name="month"
                value={formData.curMonthYear}
                onChange={(t) => {
                  setformData({
                    ...formData,
                    curMonthYear: t.target.value,
                  });
                }}
              >
                {months.map((item) => (
                  <option value={item.value}>{item.name}</option>
                ))}
              </select>
              {fromDataError.curMonthYear && (
                <span className="text-danger">{fromDataError.curMonthYear}</span>
              )}
            </div>
          </div>
        </div>
      
        <DashbordQTD dashbordDetails={dashbordDetails} dashbordDetailsByQuarter={dashbordDetailsByQuarter} funderType={funderType} />
        
      </div>
    </div>
  );
};

const mapStateStore = (stateStore) => {
  return {
    user: stateStore.userState.user,
    userData: stateStore.userState.userDetails,

  };
};

export default connect(mapStateStore)(Dashbord);

//  block and district drop-down
// <div className={'_input_container'}>
// <label className={'_label'}>Select District </label>
// <select
//   className={'_input'}
//   style={{ cursor: 'pointer' }}
//   id="year"
//   name="year"
//   disabled
//   // disabled={
//   //   formData.state_id?.length === 1 ||
//   //   formData.year !== '2021-2022'
//   //     ? false
//   //     : true
//   // }
//   value={formData.district_id}
//   onChange={(t) => {
//     setformData({
//       ...formData,
//       district_id: t.target.value,
//       districtName: t.target.selectedOptions[0].text,
//     });
//   }}
// >
//   <option value="" selected>
//     All District
//   </option>
//   {districtList.map((item) => (
//     <option id={item.name} value={item._id}>
//       {item.name}
//     </option>
//   ))}
// </select>
// {/* {formDataError.year && (
// <span className="text-danger">{formDataError.year}</span>
// )} */}
// </div>
// <div className={'_input_container'}>
// <label className={'_label'}>Select Blocks</label>
// <select
//   className={'_input'}
//   style={{ cursor: 'pointer' }}
//   id="year"
//   name="year"
//   disabled
//   // disabled={formData.state_id.length === 0 ? true : false}
//   value={formData.block_id}
//   onChange={(t) => {
//     setformData({
//       ...formData,
//       block_id: t.target.value,
//       blockName: t.target.selectedOptions[0].text,
//     });
//   }}
// >
//   <option value="" selected>
//     Select
//   </option>
//   {blockList.map((item) => (
//     <option id={item.name} value={item._id}>
//       {item.name}
//     </option>
//   ))}
// </select>
// {/* {formDataError.year && (
// <span className="text-danger">{formDataError.year}</span>
// )} */}
// </div>
