import React from 'react';
import './css/dashbord.css';
import Capsules from './dashbord/capsules';
import Chart from 'react-apexcharts'


const DashbordYTD = (props) => {
    const dashbordDetails = (props.dashbordDetails) ? props.dashbordDetails : "";
    const dashbordDetailsByQuarter = (props.dashbordDetailsByQuarter) ? props.dashbordDetailsByQuarter : "";  
    return (
        <>
        <div className='section_2a' >
            <Capsules name={"Program Expenses (YTD)\n(in Lakhs)"} value={dashbordDetailsByQuarter?.staticData?.programYTDCount?.toLocaleString('hi-IN')} />
            <Capsules name={"Program Expenses \n% of Annual Budget (YTD)"} value={dashbordDetailsByQuarter?.staticData?.programYTDPercent?.toLocaleString('hi-IN')+' %'} />
            <Capsules name={'Kits Distributed'} value={dashbordDetailsByQuarter?.staticYTDData?.kitsDistributed?.toLocaleString('hi-IN')} />
            <Capsules name={'No. Of Sparks'} value={dashbordDetailsByQuarter?.staticData?.spark?.toLocaleString('hi-IN')} />
            <Capsules name={'Sampark TV/LED distributed'} value={dashbordDetailsByQuarter?.staticYTDData?.stvLed?.toLocaleString('hi-IN')} />
          </div>
          <div className='section_2b' >
            <Capsules name={"No. of schools in \nthe state"} value={dashbordDetailsByQuarter?.staticYTDData?.totalSchools?.toLocaleString('hi-IN')} />
            <Capsules name={'% of schools covered'} value={dashbordDetailsByQuarter?.staticYTDData?.schoolsCoveredPercent+' %'} />
            <Capsules name={'No. of students in\nthe State'} value={dashbordDetailsByQuarter?.staticYTDData?.totalStudents?.toLocaleString('hi-IN')} />
            <Capsules name={'% of students covered'} value={dashbordDetailsByQuarter?.staticYTDData?.studentsCoveredPercent+' %'} />
          </div>
          {/* Teacher Training */}
          <div className='section_3'>
            <div className='section_3a'>
              <div className='section_3_head_a_container' >
                <div className='section_3_head1'>Teacher Training</div>
              </div>
              <div className='section_3_head_b_container'>
                <div className='section_3_head1'>
                  Teaching Acceptance Measurement
                </div>
              </div>
            </div>
            <div className='section_3b'>
              <div className='section_3_b1' >
                <Capsules name={"Teachers trained"} value={dashbordDetailsByQuarter?.dashboardData?.teachers_trained_ytd?.toLocaleString('hi-IN') || (dashbordDetailsByQuarter?.staticYTDData?.teachers_trained).toLocaleString('hi-IN')} />
                <Capsules name={"MT Trained"} value={dashbordDetailsByQuarter?.dashboardData?.mt_trained_ytd?.toLocaleString('hi-IN') || (dashbordDetailsByQuarter?.staticYTDData?.mt_trained).toLocaleString('hi-IN')} />
              </div>
              <div className='section_3b_container' >
                <Capsules name={"No of feedbacks"} value={dashbordDetailsByQuarter?.dashboardData?.no_of_feedbacks_ytd?.toLocaleString('hi-IN') || (dashbordDetailsByQuarter?.previousData['no_of_feedbacks_qtd'] + dashbordDetailsByQuarter?.schoolData['total_feedback'] + dashbordDetailsByQuarter?.meetingFeedback['total_feedback'] + dashbordDetailsByQuarter?.trainingData['total_feedback']).toLocaleString('hi-IN')} />
                <Capsules name={"Training acceptance"} value={`${dashbordDetailsByQuarter?.dashboardData?.training_acceptance_ytd?.toLocaleString('hi-IN') || dashbordDetails?.trainingData['rating']}`.slice(0, 3) + "/5"} />
                <Capsules name={"Program acceptance"} value={`${dashbordDetailsByQuarter?.dashboardData?.program_acceptance_ytd?.toLocaleString('hi-IN') || dashbordDetails?.schoolData['rating']}`.slice(0, 3) + "/5"} />
              </div>
            </div>
          </div>
          {/* classroom impact measured */}
          <div className='section_4' >
            <div className='section_4a'>
              <div className='section_3_head1'>
                Classroom Impact Measured
              </div>
            </div>
            <div className='section_4b'>
              <div className='section_4_card_1' >
                <div className='card_head'>Registered Users</div>
                <div className='card_head_2'>{dashbordDetailsByQuarter?.dashboardData?.registered_users_ytd?.toLocaleString('hi-IN') || (dashbordDetailsByQuarter?.previousData?.registered_users_qtd + dashbordDetailsByQuarter?.registeredUsers?.users_registered).toLocaleString('hi-IN')}<span>{` (YTD)`}</span></div>
                <div className='card_head_3'><div>{dashbordDetailsByQuarter?.dashboardData?.registered_users_Since_Inception?.toLocaleString('hi-IN') || ((dashbordDetails?.inceptionData?.registered_users || 0) + dashbordDetailsByQuarter?.previousData?.registered_users_qtd + dashbordDetailsByQuarter?.registeredUsers?.users_registered).toLocaleString('hi-IN')}</div> <span> {`(Since Inception)`}</span></div>
              </div>
              <div className='section_4_card_2' >
                <div className='card_head'>Total Resources Used</div>
                <div className='card_head_2'>{dashbordDetailsByQuarter?.dashboardData?.total_resources_used_ytd?.toLocaleString('hi-IN') || (dashbordDetailsByQuarter?.previousData?.total_resources_used_qtd + dashbordDetailsByQuarter?.resourcesUsedTotal).toLocaleString('hi-IN')}<span>{` (YTD)`}</span></div>
                <div className='card_head_3'><div>{dashbordDetailsByQuarter?.dashboardData?.total_resources_used_Since_Inception?.toLocaleString('hi-IN') || ((dashbordDetails?.inceptionData?.total_resources_used || 0) + dashbordDetailsByQuarter?.previousData?.total_resources_used_qtd + dashbordDetailsByQuarter?.resourcesUsedTotal).toLocaleString('hi-IN')}</div> <span> {`(Since Inception)`}</span></div>
              </div>
              <div className='section_4_card_3' >
                <div className='card_head'>Subject Resources Used (YTD)</div>
                <div className='card_3_line_1'>
                  <div className='card_2_lable' >English</div>
                  <div className='card_2_value'>{dashbordDetailsByQuarter?.dashboardData?.english_resources_used_ytd?.toLocaleString('hi-IN') || (dashbordDetailsByQuarter?.previousData['english_resources_used_qtd'] + dashbordDetailsByQuarter?.resourcesUsed['english']).toLocaleString('hi-IN')}</div>
                </div>
                <div className='card_3_line_2' >
                  <div className='card_2_lable'>Math</div>
                  <div className='card_2_value'>{dashbordDetailsByQuarter?.dashboardData?.math_resources_used_ytd?.toLocaleString('hi-IN') || (dashbordDetailsByQuarter?.previousData['math_resources_used_qtd'] + dashbordDetailsByQuarter?.resourcesUsed['math']).toLocaleString('hi-IN')}</div>
                </div>
                <div className='card_3_line_3' >
                  <div className='card_2_lable'>Science</div>
                  <div className='card_2_value'>{dashbordDetailsByQuarter?.dashboardData?.science_resources_used_ytd?.toLocaleString('hi-IN') || (dashbordDetailsByQuarter?.previousData['science_resources_used_qtd'] + dashbordDetailsByQuarter?.resourcesUsed['science']).toLocaleString('hi-IN')}</div>
                </div>
                <div className='card_3_line_4' >
                  <div className='card_2_lable'>Other Subjects</div>
                  <div className='card_2_value'>{dashbordDetailsByQuarter?.dashboardData?.other_subject_resources_used_ytd?.toLocaleString('hi-IN') || (dashbordDetailsByQuarter?.previousData['other_subject_resources_used_qtd'] + dashbordDetailsByQuarter?.resourcesUsed['otherSubjects']).toLocaleString('hi-IN')}</div>
                </div>
              </div>
              <div className='section_4_card_3' >
                <div className='card_head'>Class Resources Used (YTD)</div>
                <div className='card_2_line_1' >
                  <div className='card_2_lable'>FLN</div>
                  <div className='card_2_value'>{dashbordDetailsByQuarter?.dashboardData?.fln_resources_used_ytd?.toLocaleString('hi-IN') || (dashbordDetailsByQuarter?.previousData?.fln_resources_used_qtd + dashbordDetailsByQuarter?.resourcesUsed['FLN']).toLocaleString('hi-IN')}</div>
                </div>
                <div className='card_2_line_2'>
                  <div className='card_2_lable'>Class 6- 8</div>
                  <div className='card_2_value'>{dashbordDetailsByQuarter?.dashboardData?.class68_resources_used_ytd?.toLocaleString('hi-IN') || (dashbordDetailsByQuarter?.previousData?.class68_resources_used_qtd + dashbordDetailsByQuarter?.resourcesUsed['class6_8']).toLocaleString('hi-IN')}</div>
                </div>
                <div className='card_2_line_3'>
                  <div className='card_2_lable'>Other</div>
                  <div className='card_2_value'>{dashbordDetailsByQuarter?.dashboardData?.otherclass_resources_used_ytd?.toLocaleString('hi-IN') || (dashbordDetailsByQuarter?.previousData?.otherclass_resources_used_qtd + dashbordDetailsByQuarter?.resourcesUsed['otherClass']).toLocaleString('hi-IN')}</div>
                </div>
              </div>
            </div>
          </div>

          {/* Program monitoring evaluation */}
          <div className='section_5' >
            <div className='section_4a'>
              <div className='section_3_head1'>Program Monitoring Evaluation</div>
            </div>
            <div className='section_5b' >
              <div className='section_5_capsules'>
                <Capsules name={"School audit and review"} value={dashbordDetailsByQuarter?.dashboardData?.school_audit_and_review_ytd?.toLocaleString('hi-IN') || (dashbordDetailsByQuarter?.previousData?.school_audit_and_review_qtd + dashbordDetailsByQuarter?.schoolVisits?.visitcount - dashbordDetailsByQuarter?.managerSchoolVIsits?.visitcount + dashbordDetailsByQuarter?.meetingData?.meeting_count - dashbordDetailsByQuarter?.managerMeetingData?.meeting_count).toLocaleString('hi-IN')} />
                <Capsules name={"State advocacy Meeting"} value={dashbordDetailsByQuarter?.dashboardData?.state_advocacy_meeting_ytd?.toLocaleString('hi-IN') || (dashbordDetailsByQuarter?.previousData?.state_advocacy_meeting_qtd + dashbordDetailsByQuarter?.managerSchoolVIsits?.visitcount + dashbordDetailsByQuarter?.managerMeetingData?.meeting_count).toLocaleString('hi-IN')} />
              </div>
            </div>
          </div>
        </>
        
    );
}

export default DashbordYTD
