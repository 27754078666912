import axios from 'axios';
import React, { useEffect, useState } from 'react';
import configKeys from '../config/keys';
import inputValidation from '../constants/inputValidaation';

import { connect } from 'react-redux';
import './css/schoolImpacted.css';
import {  useNavigate, useParams } from 'react-router-dom';


const StaticDataEdit = (props) => {
    const params = useParams();
    const userDetails = props.userData

    const month = { '1': 'January, February, March' ,
    '4': 'April, May, June' ,
    '7': 'July, August, September' ,
    '10': 'October, November, December' };
    
    const user = props.user

    const navigate = useNavigate();
    const [funderMonths, setFunderMonths] = useState({})
    const [Loading, setLoading] = useState({
        loading: false,
        success: '',
        error: '',
      });
      const [requestAuth, setRequestAuth] = useState({
        success: '',
        error: '',
        loading: false,
      });
      const [funderList, setFunderList] = useState([]);
      const [formData, setformData] = useState({
        year: '2023',
        quarter: '',
        funderId: '', 
        yearly_budget:'',
        spark_count: '',
        districts_covered: '',
        blocks_covered: '',
        stv_distributed: '',
        led_distributed: '',
        kits_distributed: '',
        teachers_trained: '',
        mt_trained: '',
        total_schools_in_state: '',
        schools_covered: '',
        total_students_in_state: '',
        students_covered: '',
        girl_child_percent: '',
        program_expenses_ytd: '',
        program_expenses_qtd: ''
      });
      const [fromDataError, setFromDataError] = useState({
        year: '',
        quarter: '',
        funderId: ''
      });
      // const [userList, setuserList] = useState([])
    //   const [selectedUsers, setselectedUsers] = useState('')
    
    const token = JSON.parse(localStorage.getItem('user'));
    useEffect(() => {
      if (!user || user === null) {
        return navigate('/login');
      }
      if (!token) return navigate('/login');
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);
    
    useEffect(() => {
      if(userDetails?.admintype !== "superadmin" && userDetails?.admintype !== "program_manager"){
        return navigate('/');
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
      
      useEffect(() => {
        // getStateList();
        getFunderList();
        getStaticDataDetails()
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
      
      const getMonths = async(selectedFunder) => {
        let startMonth = parseInt(selectedFunder['programStartMonth'])
        let startYear = selectedFunder['programStartYear']
        let endMonth = parseInt(selectedFunder['programEndMonth'])
        let endYear = selectedFunder['programEndYear']
        const date = new Date();
        const curYear = date.getFullYear()
        const curMonth = date.getMonth()+1
        let tempmonths = []
        let quarter = 1
        if (startYear === endYear) {
          for (let i=startMonth;i<=endMonth;i=i+3) {
            if (parseInt(startYear) < parseInt(curYear)) {
              tempmonths.push({name:month[i.toString()]+' - '+startYear,value:quarter})
            } else if (parseInt(startYear) === parseInt(curYear) && i <= curMonth) {
              tempmonths.push({name:month[i.toString()]+' - '+startYear,value:quarter})
            }
            quarter = quarter + 1
          }
        } else {
          let tempYear = parseInt(startYear)
          while (tempYear <= parseInt(endYear)) 
          { 
            let tempStartMonth = 1
            let tempEndMonth = 12
            if (tempYear === parseInt(startYear)) {
              tempStartMonth = startMonth
            }
            if (tempYear === parseInt(endYear)) {
              tempEndMonth = endMonth
            }
            for (let i=tempStartMonth;i<=tempEndMonth;i=i+3) {
              if ((parseInt(tempYear) === parseInt(curYear) && i <= curMonth) || (parseInt(tempYear) < parseInt(curYear))) {
                tempmonths.push({name:month[i.toString()]+' - '+tempYear,value:quarter})
                quarter = quarter + 1
              }
            }
            tempYear = tempYear + 1
          }
        }
        funderMonths[selectedFunder['_id']] = tempmonths
        setFunderMonths(funderMonths)
      }
      const getStaticDataDetails = async () => {
        try {
          setLoading({
            loading: true,
            success: '',
            error: '',
          });
    
          const { data } = await axios(`${configKeys.SERVER_URL}/getStaticData/${params.id}`);

          if (data.data.quarter.toString() === "0") {
            //setIsQuarterDisabled(true)
            //setIsYearDisabled(false)
          } else {
            //setIsQuarterDisabled(false)
            //setIsYearDisabled(true)
          }
          setformData({
            year: data.data.year,
            quarter: data.data.quarter,
            funderId:data.data.funder_id,
            spark_count: data.data.spark_count,
            yearly_budget: data.data.yearly_budget,
            led_distributed: data.data.led_distributed,
            stv_distributed: data.data.stv_distributed,
            kits_distributed: data.data.kits_distributed,
            mt_trained: data.data.mt_trained,
            teachers_trained: data.data.teachers_trained,
            total_schools_in_state: data.data.total_schools_in_state,
            schools_covered: data.data.schools_covered,
            districts_covered: data.data.districts_covered,
            blocks_covered: data.data.blocks_covered,
            total_students_in_state: data.data.total_students_in_state,
            students_covered: data.data.students_covered,
            girl_child_percent: data.data.girl_child_percent,
            program_expenses_ytd: data.data.program_expenses_ytd,
            program_expenses_qtd: data.data.program_expenses_qtd,
          })
    
          setLoading({
            loading: false,
            success: '',
            error: '',
          });
        } catch (error) {
          setLoading({
            loading: false,
            success: '',
            error: error.message,
          });
        }
      };
      const getFunderList = async () => {
  
        try {
          setLoading({
            loading: true,
            success: '',
            error: '',
          });
          const { data } = await axios(`${configKeys.SERVER_URL}/v3/funderGroupList?label=true`);
          setFunderList(data.data);
          data.data?.map((item) => (
            getMonths(item)
          ))
          setLoading({
            loading: false,
            success: '',
            error: '',
          });
        } catch (error) {
          setLoading({
            loading: false,
            success: '',
            error: error.message,
          });
        }
      };
    
      const validateForm = () => {
        let hasError = false;
    
        let tempError = {
          year: '',
          quarter: '',
          funderId: ''
        };
    
        tempError.funderId = inputValidation.isInputEmpty(formData.funderId);
        if (tempError.funderId !== '') {
          hasError = true;
        }
        // tempError.district_id = inputValidation.isInputEmpty(formData.district_id);
        // if (tempError.district_id !== '') {
        //   hasError = true;
        // }
        // tempError.quarter = inputValidation.isInputEmpty(formData.quarter);
        // if (tempError.quarter !== '') {
        //   hasError = true;
        // }
        tempError.year = inputValidation.isInputEmpty(formData.year);
        
    
        setFromDataError({
          ...fromDataError,
          ...tempError,
        });
    
        return hasError;
      };

    
      const onSubmit = async () => {
        if (validateForm()) {
          return;
        }
    
        try {
          setRequestAuth({
            loading: true,
            error: '',
            success: '',
          });
          let staticData = {}
          staticData = formData
          staticData['yearly_budget'] = formData.yearly_budget
          staticData['program_expenses_ytd'] = formData.program_expenses_ytd
          staticData['program_expenses_qtd'] = formData.program_expenses_qtd
          staticData['districts_covered'] = formData.districts_covered
          staticData['blocks_covered'] = formData.blocks_covered
          staticData['total_schools_in_state'] = formData.total_schools_in_state
          staticData['schools_covered'] = formData.schools_covered
          staticData['total_students_in_state'] = formData.total_students_in_state
          staticData['students_covered'] = formData.students_covered
          staticData['girl_child_percent'] = formData.girl_child_percent
          staticData['spark_count'] = formData.spark_count
          staticData['stv_distributed'] = formData.stv_distributed
          staticData['led_distributed'] = formData.led_distributed
          staticData['kits_distributed'] = formData.kits_distributed
          staticData['teachers_trained'] = formData.teachers_trained
          staticData['mt_trained'] = formData.mt_trained
          staticData['quarter'] = formData.quarter
          staticData['year'] = formData.year

          const { data } = await axios.post(`${configKeys.SERVER_URL}/updateStaticData/${params.id}`,{ ...formData }, { headers: { "Authorization": token.token } });
          setRequestAuth({
            loading: false,
            error: '',
            success: data.message,
          });
          setTimeout(() => {
            navigate(-1)
          }, 2000);
        } catch (error) {
          setRequestAuth({
            loading: false,
            error: error?.response?.data?.message,
            success: '',
          });
        }
      };
  return (
    <div className="container">
      <div className="mt-5 mb-4 schoolHead ">Static Data Edit</div>
      <div className="d-flex flex-wrap">
        <div className={'_input_container flex_per_row_for_filters'}>
          <label className={'_label'}>Select Program </label>
          <select
            className={'_input'}
            style={{ cursor: 'pointer' }}
            id="user"
            name="user"
            disabled
            value={formData.funderId}
            onChange={(t) => {
              setformData({
                ...formData,
                funderId: t.target.value,
              });
            }}
          >
            <option value="" selected>
              Select
            </option>
            {!Loading.loading &&  funderList?.map((item) => (
              <option value={item.value}>{item.label}</option>
            ))}
          </select>
          {fromDataError.funderId && (
            <span className="text-danger">{fromDataError.funderId}</span>
          )}
        </div>
        <div className={'_input_container flex_per_row_for_filters'} style={{display:'none'}}>
          <label className={'_label'}>Select Year</label>
          <select
            className={'_input'}
            style={{ cursor: 'pointer' }}
            id="year"
            name="year"
            disabled
            value={formData.year}
            onChange={(t) => {
              setformData({
                ...formData,
                year: t.target.value,
              });
            }}
          >
            <option value="" selected disabled>
              Select
            </option>
            <option value="2023">2023-2024</option>
            <option value="2024">2024-2025</option>
          </select>
          {fromDataError.year && (
            <span className="text-danger">{fromDataError.year}</span>
          )}
        </div>
        <div className={'_input_container flex_per_row_for_filters'}>
          <label className={'_label'}>Select Quarter </label>
          <select
            className={'_input'}
            style={{ cursor: 'pointer' }}
            id="month"
            name="month"
            disabled
            value={formData.quarter}
            onChange={(t) => {
              setformData({
                ...formData,
                quarter: t.target.value,
              });
            }}
          >
            <option value="" selected>
              Select
            </option>
            {funderMonths[formData.funderId] && funderMonths[formData.funderId].map((item) => (
              <option value={item.value}>{item.name}</option>
            ))}
          </select>
          {fromDataError.quarter && (
            <span className="text-danger">{fromDataError.quarter}</span>
          )}
        </div>
      </div>

      <div className='d-flex flex-wrap mt-5'>
        <div className={'_input_container flex_per_row'}>
          <label className={'_label'}>Yearly Budget (ex: 100000000)</label>
          <input
            className={'_input'}
            style={{ cursor: 'pointer' }}
            id="count"
            name="count"
            type={'Number'}
            value={formData.yearly_budget}
            placeholder={'Enter Yearly Budget'}
            onChange={(t) => {
              setformData({
                ...formData,
                yearly_budget: t.target.value,
              });
            }}
          />

          {fromDataError.yearly_budget && (
            <span className="text-danger">{fromDataError.yearly_budget}</span>
          )}
        </div>
        <div className={'_input_container flex_per_row'}>
          <label className={'_label'}>Program Expenses YTD (in Lakhs) (ex: 12.56)</label>
          <input
            className={'_input'}
            style={{ cursor: 'pointer' }}
            id="count"
            name="count"
            type={'Number'}
            value={formData.program_expenses_ytd}
            placeholder={'Enter Count'}
            onChange={(t) => {
              setformData({
                ...formData,
                program_expenses_ytd: t.target.value,
              });
            }}
          />

          {fromDataError.program_expenses_ytd && (
            <span className="text-danger">{fromDataError.program_expenses_ytd}</span>
          )}
        </div>
        <div className={'_input_container flex_per_row'}>
          <label className={'_label'}>Program Expenses QTD (in Lakhs) (ex: 12.56)</label>
          <input
            className={'_input'}
            style={{ cursor: 'pointer' }}
            id="count"
            name="count"
            type={'Number'}
            value={formData.program_expenses_qtd}
            placeholder={'Enter Count'}
            onChange={(t) => {
              setformData({
                ...formData,
                program_expenses_qtd: t.target.value,
              });
            }}
          />

          {fromDataError.program_expenses_qtd && (
            <span className="text-danger">{fromDataError.program_expenses_qtd}</span>
          )}
        </div>
        <div className={'_input_container flex_per_row'}>
          <label className={'_label'}>Kits Distributed </label>
          <input
            className={'_input'}
            style={{ cursor: 'pointer' }}
            id="count"
            name="count"
            type={'Number'}
            value={formData.kits_distributed}
            placeholder={'Enter Count'}
            onChange={(t) => {
              setformData({
                ...formData,
                kits_distributed: t.target.value,
              });
            }}
          />

          {fromDataError.kits_distributed && (
            <span className="text-danger">{fromDataError.kits_distributed}</span>
          )}
        </div>
        <div className={'_input_container flex_per_row'}>
          <label className={'_label'}>Sampark TV Distributed </label>
          <input
            className={'_input'}
            style={{ cursor: 'pointer' }}
            id="count"
            name="count"
            type={'Number'}
            value={formData.stv_distributed}
            placeholder={'Enter Count'}
            onChange={(t) => {
              setformData({
                ...formData,
                stv_distributed: t.target.value,
              });
            }}
          />

          {fromDataError.stv_distributed && (
            <span className="text-danger">{fromDataError.stv_distributed}</span>
          )}
        </div>
        <div className={'_input_container flex_per_row'}>
          <label className={'_label'}>LED Distributed </label>
          <input
            className={'_input'}
            style={{ cursor: 'pointer' }}
            id="count"
            name="count"
            type={'Number'}
            value={formData.led_distributed}
            placeholder={'Enter Count'}
            onChange={(t) => {
              setformData({
                ...formData,
                led_distributed: t.target.value,
              });
            }}
          />

          {fromDataError.led_distributed && (
            <span className="text-danger">{fromDataError.led_distributed}</span>
          )}
        </div>
        <div className={'_input_container flex_per_row'}>
          <label className={'_label'}>No. Of Sparks </label>
          <input
            className={'_input'}
            style={{ cursor: 'pointer' }}
            id="count"
            name="count"
            type={'Number'}
            value={formData.spark_count}
            placeholder={'Enter Count'}
            onChange={(t) => {
              setformData({
                ...formData,
                spark_count: t.target.value,
              });
            }}
          />

          {fromDataError.spark_count && (
            <span className="text-danger">{fromDataError.spark_count}</span>
          )}
        </div>
        <div className={'_input_container flex_per_row'}>
          <label className={'_label'}>Teachers Trained </label>
          <input
            className={'_input'}
            style={{ cursor: 'pointer' }}
            id="count"
            name="count"
            type={'Number'}
            value={formData.teachers_trained}
            placeholder={'Enter Count'}
            onChange={(t) => {
              setformData({
                ...formData,
                teachers_trained: t.target.value,
              });
            }}
          />

          {fromDataError.teachers_trained && (
            <span className="text-danger">{fromDataError.teachers_trained}</span>
          )}
        </div>
        <div className={'_input_container flex_per_row'}>
          <label className={'_label'}>MT Trained </label>
          <input
            className={'_input'}
            style={{ cursor: 'pointer' }}
            id="count"
            name="count"
            type={'Number'}
            value={formData.mt_trained}
            placeholder={'Enter Count'}
            onChange={(t) => {
              setformData({
                ...formData,
                mt_trained: t.target.value,
              });
            }}
          />

          {fromDataError.mt_trained && (
            <span className="text-danger">{fromDataError.mt_trained}</span>
          )}
        </div>
        

        <div className={'_input_container flex_per_row'}>
          <label className={'_label'}>No. Of Schools In State </label>
          <input
            className={'_input'}
            style={{ cursor: 'pointer' }}
            id="count"
            name="count"
            type={'Number'}
            value={formData.total_schools_in_state}
            placeholder={'Enter Count'}
            onChange={(t) => {
              setformData({
                ...formData,
                total_schools_in_state: t.target.value,
              });
            }}
          />

          {fromDataError.total_schools_in_state && (
            <span className="text-danger">{fromDataError.total_schools_in_state}</span>
          )}
        </div>
        <div className={'_input_container flex_per_row'}>
          <label className={'_label'}>No. Of Students In State </label>
          <input
            className={'_input'}
            style={{ cursor: 'pointer' }}
            id="count"
            name="count"
            type={'Number'}
            value={formData.total_students_in_state}
            placeholder={'Enter Count'}
            onChange={(t) => {
              setformData({
                ...formData,
                total_students_in_state: t.target.value,
              });
            }}
          />

          {fromDataError.total_students_in_state && (
            <span className="text-danger">{fromDataError.total_students_in_state}</span>
          )}
        </div>
        <div className={'_input_container flex_per_row'}>
          <label className={'_label'}>Percentage of Girl Child </label>
          <input
            className={'_input'}
            style={{ cursor: 'pointer' }}
            id="count"
            name="count"
            type={'Number'}
            value={formData.girl_child_percent}
            placeholder={'Enter Count'}
            onChange={(t) => {
              setformData({
                ...formData,
                girl_child_percent: t.target.value,
              });
            }}
          />

          {fromDataError.girl_child_percent && (
            <span className="text-danger">{fromDataError.girl_child_percent}</span>
          )}
        </div>
        <div className={'_input_container flex_per_row'}>
          <label className={'_label'}>Districts Covered </label>
          <input
            className={'_input'}
            style={{ cursor: 'pointer' }}
            id="count"
            name="count"
            type={'Number'}
            value={formData.districts_covered}
            placeholder={'Enter Count'}
            onChange={(t) => {
              setformData({
                ...formData,
                districts_covered: t.target.value,
              });
            }}
          />

          {fromDataError.districts_covered && (
            <span className="text-danger">{fromDataError.districts_covered}</span>
          )}
        </div>
        <div className={'_input_container flex_per_row'}>
          <label className={'_label'}>Blocks Covered </label>
          <input
            className={'_input'}
            style={{ cursor: 'pointer' }}
            id="count"
            name="count"
            type={'Number'}
            value={formData.blocks_covered}
            placeholder={'Enter Count'}
            onChange={(t) => {
              setformData({
                ...formData,
                blocks_covered: t.target.value,
              });
            }}
          />

          {fromDataError.blocks_covered && (
            <span className="text-danger">{fromDataError.blocks_covered}</span>
          )}
        </div>
        <div className={'_input_container flex_per_row'}>
          <label className={'_label'}>No. of Schools Covered </label>
          <input
            className={'_input'}
            style={{ cursor: 'pointer' }}
            id="count"
            name="count"
            type={'Number'}
            value={formData.schools_covered}
            placeholder={'Enter Count'}
            onChange={(t) => {
              setformData({
                ...formData,
                schools_covered: t.target.value,
              });
            }}
          />

          {fromDataError.schools_covered && (
            <span className="text-danger">{fromDataError.schools_covered}</span>
          )}
        </div>
        <div className={'_input_container flex_per_row'}>
          <label className={'_label'}>No. of Students Covered </label>
          <input
            className={'_input'}
            style={{ cursor: 'pointer' }}
            id="count"
            name="count"
            type={'Number'}
            value={formData.students_covered}
            placeholder={'Enter Count'}
            onChange={(t) => {
              setformData({
                ...formData,
                students_covered: t.target.value,
              });
            }}
          />

          {fromDataError.students_covered && (
            <span className="text-danger">{fromDataError.students_covered}</span>
          )}
        </div>
        <div className={'_input_container flex_per_row'}>
        </div>
        <div className={'_input_container flex_per_row'}>
        </div>
      </div>
   
     
      {requestAuth.loading && (
            <div className="rounded mt-1 mb-1 p-1 border border-info text-center text-info">
              Loading...
            </div>
          )}
          {requestAuth.success && (
            <div className="rounded mt-1 mb-1 p-1 border border-success text-center text-success">
              {requestAuth.success}
            </div>
          )}
          {requestAuth.error && (
            <div className="rounded mt-1 mb-1 p-1 border border-danger text-center text-danger">
              {requestAuth.error}
            </div>
          )}
      <div className="text-left mt-4">
        <button className=" btn btnSchool bg-success text-white" onClick={() => onSubmit()}>
          Submit
        </button>
        <button onClick={()=> navigate(-1)} className='btn btnSchool bg-danger text-white' to="/">Cancel</button>
      </div>
    </div>
  )
}

const mapStateStore = (stateStore) => {
  return {
    user: stateStore.userState.user,
    userData: stateStore.userState.userDetails,
  };
};

export default connect(mapStateStore,{})(StaticDataEdit)
